@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.image-grid-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {
		display: flex;
		gap: var(--wp--preset--spacing--60);
		flex-wrap: wrap;

		@media only screen and (max-width: bp.$breakpoint-small) {
			gap: 0;
		}

		.image-grid-block__title {
			flex: 0 1 40%;

			@media only screen and (max-width: bp.$breakpoint-small) {
				flex: 1 1 40%;
			}
		}

		.image-grid-block__content {
			flex: 1 1 200px;
		}
	}

	&.contained {

		.bnpp-container {
			padding: var(--wp--preset--spacing--60);
		}

	}

	.image-grid-block__list {
		@include mixins.grid(var(--imageGridSize, 250px));
		margin-top: var(--wp--preset--spacing--40);
	}

	&.image-grid-block--alternate {

		.image-grid-module {

			figure {

				grid-template-columns: 1fr;
				gap: 0;

				figcaption {
					padding: var(--wp--preset--spacing--40);
					font-weight: 400;
					background-color: var(--wp--preset--color--bnpp-green-tint-10);
				}

			}

		}

	}


}